var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid item-align-center"},[_c('div',{staticClass:"main_wrapper col-12"},[_c('div',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"tv"},[_c('div',{staticClass:"cruve"},[_c('svg',{staticClass:"curve_svg",attrs:{"xml:space":"preserve","viewBox":"0 0 189.929 189.929","xmlns:xlink":"http://www.w3.org/1999/xlink","xmlns":"http://www.w3.org/2000/svg","version":"1.1"}},[_c('path',{attrs:{"d":"M70.343,70.343c-30.554,30.553-44.806,72.7-39.102,115.635l-29.738,3.951C-5.442,137.659,11.917,86.34,49.129,49.13\n    C86.34,11.918,137.664-5.445,189.928,1.502l-3.95,29.738C143.041,25.54,100.895,39.789,70.343,70.343z"}})])]),_vm._m(1),_vm._m(2),_vm._m(3)]),_vm._m(4)]),_vm._m(5)]),_c('div',{staticClass:"col-12"},[_c('vs-button',{staticStyle:{"margin":"0 auto"},on:{"click":() => { this.$router.push('/') }}},[_vm._v("Go Back Home")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"antenna"},[_c('div',{staticClass:"antenna_shadow"}),_c('div',{staticClass:"a1"}),_c('div',{staticClass:"a1d"}),_c('div',{staticClass:"a2"}),_c('div',{staticClass:"a2d"}),_c('div',{staticClass:"a_base"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"display_div"},[_c('div',{staticClass:"screen_out"},[_c('div',{staticClass:"screen_out1"},[_c('div',{staticClass:"screen"},[_c('span',{staticClass:"notfound_text"},[_vm._v(" NOT FOUND")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lines"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"line2"}),_c('div',{staticClass:"line3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buttons_div"},[_c('div',{staticClass:"b1"},[_c('div')]),_c('div',{staticClass:"b2"}),_c('div',{staticClass:"speakers"},[_c('div',{staticClass:"g1"},[_c('div',{staticClass:"g11"}),_c('div',{staticClass:"g12"}),_c('div',{staticClass:"g13"})]),_c('div',{staticClass:"g"}),_c('div',{staticClass:"g"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"base1"}),_c('div',{staticClass:"base2"}),_c('div',{staticClass:"base3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text_404"},[_c('div',{staticClass:"text_4041"},[_vm._v("4")]),_c('div',{staticClass:"text_4042"},[_vm._v("0")]),_c('div',{staticClass:"text_4043"},[_vm._v("4")])])
}]

export { render, staticRenderFns }