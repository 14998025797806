<template>
    <div style="width: 100%;">
      <div class="pt-10">
        <div class="grid-4_xs-1_sm-2_md-2">
          <div
              :key="index"
              v-for="(labelCard, index) in labelCardList"
              class="col w-full"
          >
            <ExchangeLink
                :title="labelCard.title"
                :icon="labelCard.icon"
                :detail="labelCard.detail"
                :url="labelCard.url"
                class="text-align-center"
            />
          </div>
        </div>

        <div class="w-full" style="min-height: 30vh;" ref="tripBar1"></div>

        <div class="grid-3_xs-1_sm-2_md-2 mt-10">
          <div class="col" ref="earnExpensePie" style="min-height: 30vh;"></div>
          <div class="col" ref="tripBar" style="min-height: 30vh">
          </div>
          <div class="col" ref="tripBar2" style="min-height: 30vh;"></div>
        </div>

        <div class="grid-2_xs-1_sm-2_md-2 mt-10">
          <div class="col" style="min-height: 30vh; background-color: pink;"></div>
          <div class="col" style="min-height: 30vh; background-color: green;"></div>
        </div>
      </div>
      <div
          v-if="false"
          :style="{backgroundImage:'url('+require('../../public/bg.jpg')+')'}"
          style="width: 100vw; height: 100vh; background-position: center center; background-size: cover; background-repeat: no-repeat;">

      </div>
    </div>
</template>
<script>
import ExchangeLink from "../components/ExchangeLink.vue";
export default {
    name: 'Home',
    components: {
      ExchangeLink
    },
    data() {
      return {
        labelCardList: [
          {
            id: 1,
            title: "累计出项",
            detail: "<span style='color: red;'>¥ 128</span>",
            icon: "bx bxs-angry",
            url: "/expense"
          },
          {
            id: 2,
            title: "累计进项",
            detail: "<span style='color: green;'>¥ 128</span>",
            icon: "bx bxs-happy-heart-eyes",
            url: "/earn"
          },
          {
            id: 3,
            title: "本月出差",
            detail: "<span style='color: dodgerblue;'>20 Days</span>",
            icon: "bx bxs-calendar",
            url: "/trip"
          },
          {
            id: 4,
            title: "心愿单",
            detail: "<span style='color: green;'>1</span>/10",
            icon: "bx bxs-cart-alt",
            url: "/wish"
          }
        ]
      }
    },
    methods: {
      initTripBar2() {
        var chartDom = this.$refs.tripBar2;
        var myChart = this.echarts.init(chartDom);
        var option;

        option = {
          title: {
            text: 'Basic Radar Chart'
          },
          legend: {
            data: ['Allocated Budget', 'Actual Spending']
          },
          radar: {
            // shape: 'circle',
            indicator: [
              { name: 'Sales', max: 6500 },
              { name: 'Administration', max: 16000 },
              { name: 'Information Technology', max: 30000 },
              { name: 'Customer Support', max: 38000 },
              { name: 'Development', max: 52000 },
              { name: 'Marketing', max: 25000 }
            ]
          },
          series: [
            {
              name: 'Budget vs spending',
              type: 'radar',
              data: [
                {
                  value: [4200, 3000, 20000, 35000, 50000, 18000],
                  name: 'Allocated Budget'
                },
                {
                  value: [5000, 14000, 28000, 26000, 42000, 21000],
                  name: 'Actual Spending'
                }
              ]
            }
          ]
        };

        option && myChart.setOption(option);

      },
      initTripBar1() {
        var chartDom = this.$refs.tripBar1;
        var myChart = this.echarts.init(chartDom);
        var option;

        var xAxisData = [];
        var data1 = [];
        var data2 = [];
        for (var i = 0; i < 100; i++) {
          xAxisData.push('A' + i);
          data1.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
          data2.push((Math.cos(i / 5) * (i / 5 - 10) + i / 6) * 5);
        }
        option = {
          title: {
            text: 'Bar Animation Delay'
          },
          legend: {
            data: ['bar', 'bar2']
          },
          toolbox: {
            // y: 'bottom',
            feature: {
              magicType: {
                type: ['stack']
              },
              dataView: {},
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {},
          xAxis: {
            data: xAxisData,
            splitLine: {
              show: false
            }
          },
          yAxis: {},
          series: [
            {
              name: 'bar',
              type: 'bar',
              data: data1,
              emphasis: {
                focus: 'series'
              },
              animationDelay: function (idx) {
                return idx * 10;
              }
            },
            {
              name: 'bar2',
              type: 'bar',
              data: data2,
              emphasis: {
                focus: 'series'
              },
              animationDelay: function (idx) {
                return idx * 10 + 100;
              }
            }
          ],
          animationEasing: 'elasticOut',
          animationDelayUpdate: function (idx) {
            return idx * 5;
          }
        };

        option && myChart.setOption(option);

      },
      initTripBar() {
        var chartDom = this.$refs.tripBar;
        var myChart = this.echarts.init(chartDom);
        var option;

        var dateList = [
          ['2017-1-1', '初四'],
          ['2017-1-2', '初五'],
          ['2017-1-3', '初六'],
          ['2017-1-4', '初七'],
          ['2017-1-5', '初八', '小寒'],
          ['2017-1-6', '初九'],
          ['2017-1-7', '初十'],
          ['2017-1-8', '十一'],
          ['2017-1-9', '十二'],
          ['2017-1-10', '十三'],
          ['2017-1-11', '十四'],
          ['2017-1-12', '十五'],
          ['2017-1-13', '十六'],
          ['2017-1-14', '十七'],
          ['2017-1-15', '十八'],
          ['2017-1-16', '十九'],
          ['2017-1-17', '二十'],
          ['2017-1-18', '廿一'],
          ['2017-1-19', '廿二'],
          ['2017-1-20', '廿三', '大寒'],
          ['2017-1-21', '廿四'],
          ['2017-1-22', '廿五'],
          ['2017-1-23', '廿六'],
          ['2017-1-24', '廿七'],
          ['2017-1-25', '廿八'],
          ['2017-1-26', '廿九'],
          ['2017-1-27', '三十'],
          ['2017-1-28', '正月'],
          ['2017-1-29', '初二'],
          ['2017-1-30', '初三'],
          ['2017-1-31', '初四'],
          ['2017-2-1', '初五'],
          ['2017-2-2', '初六'],
          ['2017-2-3', '初七', '立春'],
          ['2017-2-4', '初八'],
          ['2017-2-5', '初九'],
          ['2017-2-6', '初十'],
          ['2017-2-7', '十一'],
          ['2017-2-8', '十二'],
          ['2017-2-9', '十三'],
          ['2017-2-10', '十四'],
          ['2017-2-11', '十五'],
          ['2017-2-12', '十六'],
          ['2017-2-13', '十七'],
          ['2017-2-14', '十八'],
          ['2017-2-15', '十九'],
          ['2017-2-16', '二十'],
          ['2017-2-17', '廿一'],
          ['2017-2-18', '廿二', '雨水'],
          ['2017-2-19', '廿三'],
          ['2017-2-20', '廿四'],
          ['2017-2-21', '廿五'],
          ['2017-2-22', '廿六'],
          ['2017-2-23', '廿七'],
          ['2017-2-24', '廿八'],
          ['2017-2-25', '廿九'],
          ['2017-2-26', '二月'],
          ['2017-2-27', '初二'],
          ['2017-2-28', '初三'],
          ['2017-3-1', '初四'],
          ['2017-3-2', '初五'],
          ['2017-3-3', '初六'],
          ['2017-3-4', '初七'],
          ['2017-3-5', '初八', '驚蟄'],
          ['2017-3-6', '初九'],
          ['2017-3-7', '初十'],
          ['2017-3-8', '十一'],
          ['2017-3-9', '十二'],
          ['2017-3-10', '十三'],
          ['2017-3-11', '十四'],
          ['2017-3-12', '十五'],
          ['2017-3-13', '十六'],
          ['2017-3-14', '十七'],
          ['2017-3-15', '十八'],
          ['2017-3-16', '十九'],
          ['2017-3-17', '二十'],
          ['2017-3-18', '廿一'],
          ['2017-3-19', '廿二'],
          ['2017-3-20', '廿三', '春分'],
          ['2017-3-21', '廿四'],
          ['2017-3-22', '廿五'],
          ['2017-3-23', '廿六'],
          ['2017-3-24', '廿七'],
          ['2017-3-25', '廿八'],
          ['2017-3-26', '廿九'],
          ['2017-3-27', '三十'],
          ['2017-3-28', '三月'],
          ['2017-3-29', '初二'],
          ['2017-3-30', '初三'],
          ['2017-3-31', '初四'],
          ['2017-4-1', '初五'],
          ['2017-4-2', '初六'],
          ['2017-4-3', '初七'],
          ['2017-4-4', '初八', '清明'],
          ['2017-4-5', '初九'],
          ['2017-4-6', '初十'],
          ['2017-4-7', '十一'],
          ['2017-4-8', '十二'],
          ['2017-4-9', '十三'],
          ['2017-4-10', '十四'],
          ['2017-4-11', '十五'],
          ['2017-4-12', '十六'],
          ['2017-4-13', '十七'],
          ['2017-4-14', '十八'],
          ['2017-4-15', '十九'],
          ['2017-4-16', '二十'],
          ['2017-4-17', '廿一'],
          ['2017-4-18', '廿二'],
          ['2017-4-19', '廿三'],
          ['2017-4-20', '廿四', '穀雨'],
          ['2017-4-21', '廿五'],
          ['2017-4-22', '廿六'],
          ['2017-4-23', '廿七'],
          ['2017-4-24', '廿八'],
          ['2017-4-25', '廿九'],
          ['2017-4-26', '四月'],
          ['2017-4-27', '初二'],
          ['2017-4-28', '初三'],
          ['2017-4-29', '初四'],
          ['2017-4-30', '初五'],
          ['2017-5-1', '初六'],
          ['2017-5-2', '初七'],
          ['2017-5-3', '初八'],
          ['2017-5-4', '初九'],
          ['2017-5-5', '初十', '立夏'],
          ['2017-5-6', '十一'],
          ['2017-5-7', '十二'],
          ['2017-5-8', '十三'],
          ['2017-5-9', '十四'],
          ['2017-5-10', '十五'],
          ['2017-5-11', '十六'],
          ['2017-5-12', '十七'],
          ['2017-5-13', '十八'],
          ['2017-5-14', '十九'],
          ['2017-5-15', '二十'],
          ['2017-5-16', '廿一'],
          ['2017-5-17', '廿二'],
          ['2017-5-18', '廿三'],
          ['2017-5-19', '廿四'],
          ['2017-5-20', '廿五'],
          ['2017-5-21', '廿六', '小滿'],
          ['2017-5-22', '廿七'],
          ['2017-5-23', '廿八'],
          ['2017-5-24', '廿九'],
          ['2017-5-25', '三十'],
          ['2017-5-26', '五月'],
          ['2017-5-27', '初二'],
          ['2017-5-28', '初三'],
          ['2017-5-29', '初四'],
          ['2017-5-30', '初五'],
          ['2017-5-31', '初六'],
          ['2017-6-1', '初七'],
          ['2017-6-2', '初八'],
          ['2017-6-3', '初九'],
          ['2017-6-4', '初十'],
          ['2017-6-5', '十一', '芒種'],
          ['2017-6-6', '十二'],
          ['2017-6-7', '十三'],
          ['2017-6-8', '十四'],
          ['2017-6-9', '十五'],
          ['2017-6-10', '十六'],
          ['2017-6-11', '十七'],
          ['2017-6-12', '十八'],
          ['2017-6-13', '十九'],
          ['2017-6-14', '二十'],
          ['2017-6-15', '廿一'],
          ['2017-6-16', '廿二'],
          ['2017-6-17', '廿三'],
          ['2017-6-18', '廿四'],
          ['2017-6-19', '廿五'],
          ['2017-6-20', '廿六'],
          ['2017-6-21', '廿七', '夏至'],
          ['2017-6-22', '廿八'],
          ['2017-6-23', '廿九'],
          ['2017-6-24', '六月'],
          ['2017-6-25', '初二'],
          ['2017-6-26', '初三'],
          ['2017-6-27', '初四'],
          ['2017-6-28', '初五'],
          ['2017-6-29', '初六'],
          ['2017-6-30', '初七'],
          ['2017-7-1', '初八'],
          ['2017-7-2', '初九'],
          ['2017-7-3', '初十'],
          ['2017-7-4', '十一'],
          ['2017-7-5', '十二'],
          ['2017-7-6', '十三'],
          ['2017-7-7', '十四', '小暑'],
          ['2017-7-8', '十五'],
          ['2017-7-9', '十六'],
          ['2017-7-10', '十七'],
          ['2017-7-11', '十八'],
          ['2017-7-12', '十九'],
          ['2017-7-13', '二十'],
          ['2017-7-14', '廿一'],
          ['2017-7-15', '廿二'],
          ['2017-7-16', '廿三'],
          ['2017-7-17', '廿四'],
          ['2017-7-18', '廿五'],
          ['2017-7-19', '廿六'],
          ['2017-7-20', '廿七'],
          ['2017-7-21', '廿八'],
          ['2017-7-22', '廿九', '大暑'],
          ['2017-7-23', '閏六',],
          ['2017-7-24', '初二'],
          ['2017-7-25', '初三'],
          ['2017-7-26', '初四'],
          ['2017-7-27', '初五'],
          ['2017-7-28', '初六'],
          ['2017-7-29', '初七'],
          ['2017-7-30', '初八'],
          ['2017-7-31', '初九'],
          ['2017-8-1', '初十'],
          ['2017-8-2', '十一'],
          ['2017-8-3', '十二'],
          ['2017-8-4', '十三'],
          ['2017-8-5', '十四'],
          ['2017-8-6', '十五'],
          ['2017-8-7', '十六', '立秋'],
          ['2017-8-8', '十七'],
          ['2017-8-9', '十八'],
          ['2017-8-10', '十九'],
          ['2017-8-11', '二十'],
          ['2017-8-12', '廿一'],
          ['2017-8-13', '廿二'],
          ['2017-8-14', '廿三'],
          ['2017-8-15', '廿四'],
          ['2017-8-16', '廿五'],
          ['2017-8-17', '廿六'],
          ['2017-8-18', '廿七'],
          ['2017-8-19', '廿八'],
          ['2017-8-20', '廿九'],
          ['2017-8-21', '三十'],
          ['2017-8-22', '七月'],
          ['2017-8-23', '初二', '處暑'],
          ['2017-8-24', '初三'],
          ['2017-8-25', '初四'],
          ['2017-8-26', '初五'],
          ['2017-8-27', '初六'],
          ['2017-8-28', '初七'],
          ['2017-8-29', '初八'],
          ['2017-8-30', '初九'],
          ['2017-8-31', '初十'],
          ['2017-9-1', '十一'],
          ['2017-9-2', '十二'],
          ['2017-9-3', '十三'],
          ['2017-9-4', '十四'],
          ['2017-9-5', '十五'],
          ['2017-9-6', '十六'],
          ['2017-9-7', '十七', '白露'],
          ['2017-9-8', '十八'],
          ['2017-9-9', '十九'],
          ['2017-9-10', '二十'],
          ['2017-9-11', '廿一'],
          ['2017-9-12', '廿二'],
          ['2017-9-13', '廿三'],
          ['2017-9-14', '廿四'],
          ['2017-9-15', '廿五'],
          ['2017-9-16', '廿六'],
          ['2017-9-17', '廿七'],
          ['2017-9-18', '廿八'],
          ['2017-9-19', '廿九'],
          ['2017-9-20', '八月'],
          ['2017-9-21', '初二'],
          ['2017-9-22', '初三'],
          ['2017-9-23', '初四', '秋分'],
          ['2017-9-24', '初五'],
          ['2017-9-25', '初六'],
          ['2017-9-26', '初七'],
          ['2017-9-27', '初八'],
          ['2017-9-28', '初九'],
          ['2017-9-29', '初十'],
          ['2017-9-30', '十一'],
          ['2017-10-1', '十二'],
          ['2017-10-2', '十三'],
          ['2017-10-3', '十四'],
          ['2017-10-4', '十五'],
          ['2017-10-5', '十六'],
          ['2017-10-6', '十七'],
          ['2017-10-7', '十八'],
          ['2017-10-8', '十九', '寒露'],
          ['2017-10-9', '二十'],
          ['2017-10-10', '廿一'],
          ['2017-10-11', '廿二'],
          ['2017-10-12', '廿三'],
          ['2017-10-13', '廿四'],
          ['2017-10-14', '廿五'],
          ['2017-10-15', '廿六'],
          ['2017-10-16', '廿七'],
          ['2017-10-17', '廿八'],
          ['2017-10-18', '廿九'],
          ['2017-10-19', '三十'],
          ['2017-10-20', '九月'],
          ['2017-10-21', '初二'],
          ['2017-10-22', '初三'],
          ['2017-10-23', '初四', '霜降'],
          ['2017-10-24', '初五'],
          ['2017-10-25', '初六'],
          ['2017-10-26', '初七'],
          ['2017-10-27', '初八'],
          ['2017-10-28', '初九'],
          ['2017-10-29', '初十'],
          ['2017-10-30', '十一'],
          ['2017-10-31', '十二'],
          ['2017-11-1', '十三'],
          ['2017-11-2', '十四'],
          ['2017-11-3', '十五'],
          ['2017-11-4', '十六'],
          ['2017-11-5', '十七'],
          ['2017-11-6', '十八'],
          ['2017-11-7', '十九', '立冬'],
          ['2017-11-8', '二十'],
          ['2017-11-9', '廿一'],
          ['2017-11-10', '廿二'],
          ['2017-11-11', '廿三'],
          ['2017-11-12', '廿四'],
          ['2017-11-13', '廿五'],
          ['2017-11-14', '廿六'],
          ['2017-11-15', '廿七'],
          ['2017-11-16', '廿八'],
          ['2017-11-17', '廿九'],
          ['2017-11-18', '十月'],
          ['2017-11-19', '初二'],
          ['2017-11-20', '初三'],
          ['2017-11-21', '初四'],
          ['2017-11-22', '初五', '小雪'],
          ['2017-11-23', '初六'],
          ['2017-11-24', '初七'],
          ['2017-11-25', '初八'],
          ['2017-11-26', '初九'],
          ['2017-11-27', '初十'],
          ['2017-11-28', '十一'],
          ['2017-11-29', '十二'],
          ['2017-11-30', '十三'],
          ['2017-12-1', '十四'],
          ['2017-12-2', '十五'],
          ['2017-12-3', '十六'],
          ['2017-12-4', '十七'],
          ['2017-12-5', '十八'],
          ['2017-12-6', '十九'],
          ['2017-12-7', '二十', '大雪'],
          ['2017-12-8', '廿一'],
          ['2017-12-9', '廿二'],
          ['2017-12-10', '廿三'],
          ['2017-12-11', '廿四'],
          ['2017-12-12', '廿五'],
          ['2017-12-13', '廿六'],
          ['2017-12-14', '廿七'],
          ['2017-12-15', '廿八'],
          ['2017-12-16', '廿九'],
          ['2017-12-17', '三十'],
          ['2017-12-18', '十一月'],
          ['2017-12-19', '初二'],
          ['2017-12-20', '初三'],
          ['2017-12-21', '初四'],
          ['2017-12-22', '初五', '冬至'],
          ['2017-12-23', '初六'],
          ['2017-12-24', '初七'],
          ['2017-12-25', '初八'],
          ['2017-12-26', '初九'],
          ['2017-12-27', '初十'],
          ['2017-12-28', '十一'],
          ['2017-12-29', '十二'],
          ['2017-12-30', '十三'],
          ['2017-12-31', '十四']
        ];

        var heatmapData = [];
        var lunarData = [];
        for (var i = 0; i < dateList.length; i++) {
          heatmapData.push([
            dateList[i][0],
            Math.random() * 300
          ]);
          lunarData.push([
            dateList[i][0],
            1,
            dateList[i][1],
            dateList[i][2]
          ]);
        }


        option = {
          tooltip: {
            formatter: function (params) {
              return '降雨量: ' + params.value[1].toFixed(2);
            }
          },

          visualMap: {
            show: false,
            min: 0,
            max: 300,
            calculable: true,
            seriesIndex: [2],
            orient: 'horizontal',
            left: 'center',
            bottom: 20,
            inRange: {
              color: ['#e0ffff', '#006edd'],
              opacity: 0.3
            },
            controller: {
              inRange: {
                opacity: 0.5
              }
            }
          },

          calendar: [{
            left: 'center',
            top: 'middle',
            cellSize: [40, 40],
            yearLabel: {show: false},
            orient: 'vertical',
            dayLabel: {
              firstDay: 1,
              nameMap: 'cn'
            },
            monthLabel: {
              show: false
            },
            range: '2017-03'
          }],

          series: [{
            type: 'scatter',
            coordinateSystem: 'calendar',
            symbolSize: 1,
            label: {
              show: true,
              // formatter: function (params) {
              //   var d = this.echarts.number.parseDate(params.value[0]);
              //   return d.getDate() + '\n\n' + params.value[2] + '\n\n';
              // },
              color: '#000'
            },
            data: lunarData
          }, {
            type: 'scatter',
            coordinateSystem: 'calendar',
            symbolSize: 1,
            label: {
              show: true,
              formatter: function (params) {
                return '\n\n\n' + (params.value[3] || '');
              },
              fontSize: 14,
              fontWeight: 700,
              color: '#a00'
            },
            data: lunarData
          }, {
            name: '降雨量',
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: heatmapData
          }]
        };

        option && myChart.setOption(option);



      },
      initEarnExpensePie() {
        var chartDom = this.$refs.earnExpensePie
        var myChart = this.echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              padAngle: 5,
              itemStyle: {
                borderRadius: 10
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ]
        };

        option && myChart.setOption(option);

      }
    },
    mounted() {
      this.initEarnExpensePie();
      this.initTripBar();
      this.initTripBar1();
      this.initTripBar2();
    }
}
</script>
<style>
</style>
